<template>

<app-wrapper class="head">

	<app-wrapper :content="true" class="head-content">

		<div class="head-content-left" v-if="window.is.desktop">

			<router-link class="head-link" :to="{name: 'About'}">About</router-link>

			<div class="head-link-wrapper">

				<div class="head-link">Services</div>

				<div class="head-submenu">

					<router-link v-for="(link, index) in $state.links" :key="index" class="head-submenu-link" :to="{name: 'Service', params: { service: link.url }}">{{ link.name }}</router-link>

				</div>

			</div>

			<router-link class="head-link" :to="{name: 'Clients'}">Clients</router-link>

		</div>

		<router-link class="head-logo" :to="{name: 'Home'}"><h1>Breedy Henderson</h1></router-link>

		<div class="head-content-right" v-if="window.is.desktop">

			<router-link class="head-link" :to="{name: 'News'}">Events & opinion</router-link>

			<router-link class="head-link" :to="{name: 'Contact'}">Contact</router-link>

			<button class="head-search" v-on:click="onSearchClick"><i class="fa-solid fa-magnifying-glass"></i></button>

		</div>

		<div class="head-content-device" v-if="window.is.mobile || window.is.tablet">

			<button class="head-search" v-on:click="onSearchClick"><i class="fa-solid fa-magnifying-glass"></i></button>

			<button class="head-menu" v-on:click="onToggleClick"><i class="fa-solid fa-bars" v-if="!expanded"></i><i class="fa-solid fa-times" v-if="expanded"></i></button>

		</div>

	</app-wrapper>

	<app-wrapper class="head-expand" v-if="expanded && !window.is.desktop">

		<router-link class="head-expand-link" :to="{name: 'About'}">About</router-link>
		<div class="head-expand-link" v-on:click.stop="expandedSubmenu = !expandedSubmenu">Services</div>
		<template v-if="expandedSubmenu">
		<router-link v-for="(link, index) in $state.links" :key="index" class="head-expand-link is-submenu" :to="{name: 'Service', params: { service: link.url }}">{{ link.name }}</router-link>
		</template>
		<router-link class="head-expand-link" :to="{name: 'Clients'}">Clients</router-link>
		<router-link class="head-expand-link" :to="{name: 'News'}">Events & opinion</router-link>
		<router-link class="head-expand-link" :to="{name: 'Contact'}">Contact</router-link>

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {
	
	data: function() {

		return {

			expanded: false,
			expandedSubmenu: false

		}

	},

	watch: {

		$path: function() {

			this.expanded = false
			this.expandedSubmenu = false

		}

	},

	methods: {

		onSearchClick: function() {

			this.$emit('search')

		},

		onToggleClick: function() {

			this.expanded = !this.expanded
			this.expandedSubmenu = false
				
		}

	}

}

</script>

<style scoped>

.head {
	position: fixed;
	left: 0px;
	top: 0px;
	height: 97px;
	background-color: #112A45;
	z-index: 4;
}

.is-mobile .head {
	height: 80px;
}

.head-content {
	display: flex;
}

.is-tablet .head-content,
.is-mobile .head-content {
	justify-content: space-between;
}

.head-content-left {
	display: flex;
	justify-content: flex-end;
	width: calc(50% - 134px);
}

.head-content-right {
	display: flex;
	justify-content: flex-start;
	width: calc(50% - 134px);
}

.head-content-device {
	display: flex;
	align-items: center;
}

.head-logo {
	width: 268px;
	height: 97px;
	margin: 0px 40px;
	background-image: url(~@/assets/logo.png);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: 268px auto;
	flex-shrink: 0;
}

.is-tablet .head-logo,
.is-mobile .head-logo {
	margin: 0px;
	background-position: 0% 50%;
}

.is-mobile .head-logo {
	width: 180px;
	height: 80px;
	background-size: 180px auto;
}

.head-logo h1 {
	visibility: hidden;
}

.head-link {
	height: 97px;
	line-height: 97px;
	color: #fff;
	display: block;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 1.3px;
	font-weight: 400;
	margin: 0px 18px;
	padding: 0px 10px;
}

.head-link.router-link-active {
	border-bottom: 3px solid #BBD9CD;
}

.head-link-wrapper {
	height: 97px;
	margin: 0px 40px;
}

.head-link-wrapper .head-link {
	margin: 0px;
}

.head-submenu {
	position: absolute;
	left: -40px;
	top: 97px;
	width: 420px;
	display: none;
	flex-direction: column;
	background-color: #f5f5f5;
}

.head-link-wrapper:hover .head-submenu {
	display: flex;
}

.head-submenu-link {
	color: #112a45;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.7px;
	padding: 20px 30px;
	border-bottom: 1px solid #BBD9CD;
}

.head-submenu-link:last-child {
	border-bottom: 0px;
}

.is-desktop .head-submenu-link:hover {
	background-color: #eee;
}

.is-desktop .head-link:hover {
	border-bottom: 3px solid #fff;
}

.head-content-left .head-link:first-child {
	margin-left: 0px;
}

.head-content-right .head-link:last-child {
	margin-right: 0px;
}

.head-search {
	font-size: 16px;
	color: #fff;
	margin-left: 40px;
}

.is-tablet .head-search,
.is-mobile .head-search {
	font-size: 20px;
	margin: 0px;
}

.head-menu {
	font-size: 24px;
	color: #fff;
	margin-left: 30px;
	width: 30px;
	text-align: center;
}

.is-mobile .head-menu {
	margin-left: 20px;
}

.head-expand {
	position: fixed;
	top: 97px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #1B334D;
	z-index: 3;
}

.is-mobile .head-expand {
	top: 77px;
}

.head-expand-link {
	display: block;
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 20px;
	padding: 20px 20px;
	border-bottom: 1px solid #BBD9CD;
}

.head-expand-link:last-child {
	border-bottom: 0px;
}

.head-expand-link.is-submenu {
	text-transform: none;
	background-color: #112A45;
}

</style>
